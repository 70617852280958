<template>
    <section v-show="showError" class="container mx-auto text-center">
        <img v-if="error.statusCode == 404" src="/img/illustrations/404.svg" alt="" class="h-64 mx-auto mt-10">
        <img v-else src="/img/illustrations/500.svg" alt="" class="h-64 mx-auto mt-10">

        <h2 class="mt-20 text-4xl text-gray-900" :class="{'chunkfailed-info' : isChunkLoadingFailed}">
            {{ isChunkLoadingFailed ? 'Le système a été mis à jour et requiert une mise à jour, veuillez rafraichir la page.' : error.message }}
        </h2>
        <a v-if="isChunkLoadingFailed" class="mt-4 btn btn-primary" href="https://manager.abcreche.com">
            Rafraichir la page au tableau de bord
        </a>
        <nuxt-link v-else-if="error.statusCode === 404" class="mt-4 btn btn-primary" to="/">
            Revenir sur votre tableau de bord
        </nuxt-link>
    </section>
</template>

<script>
export default {
    middleware: 'auth',

    props: {
        error: {
            type: [String, Object, Array],
            required: true
        }
    },

    data () {
        return {
            showError: false,
            isChunkLoadingFailed: false
        }
    },

    created () {
        if (!this.$auth.loggedIn) {
            this.$router.push('/login')
        }
    },

    mounted () {
        if (this.error == null) {
            this.$dialog.show({ title: 'error' })

            return
        }

        if (this.error.statusCode === 404) { // 404
            const mt = /^\/page\/(\d+)$/g.exec(this.$route.path)
            if (mt && mt.length === 2) { // wut
                // const query = this.$route.query || {}
                // location.replace(/page.shtml?id=${mt[1]}${Object.keys(query).length > 0 ? '&' : ''}${utils.serialize(query)})
                // return
            }
        }

        // webpack error
        if (this.error.statusCode === 500 && /^Loading chunk (\d)+ failed\./.test(this.error.message)) {
            this.isChunkLoadingFailed = true
        }

        this.showError = true
    }
}
</script>
